import { IApplicationError } from '../../../src/app/entities/application-error';
import { Region } from '../../../src/app/entities/codeLists/region';
import { ModalValue } from '../../../src/app/entities/modal-helper';
import {
    SupportApplicationType, SupportRequest
} from '../../../src/app/generated-modules/Hilti.PE.SupportService.Shared.Entities';
import { formatKeyValue } from '../../../src/app/helpers/string-helper';
import { CodeListService, ProjectCodeList } from '../../../src/app/services/code-list.service';
import { DateTimePattern, DateTimeService } from '../../../src/app/services/date-time.service';
import { LocalizationService } from '../../../src/app/services/localization.service';
import { LoggerService } from '../../../src/app/services/logger.service';
import { environment } from '../../../src/environments/environment';
import { BrowserService } from '../../Services/browser-service';
import { LicenseService } from '../../Services/license-service';
import { ProductInformationService } from '../../Services/product-information.service';
import { TrackingService } from '../../Services/tracking-service';
import { UserService } from '../../Services/user-service';
import { UserSettingsService } from '../../Services/user-settings-service';
import { Bind, control, Translation } from '../controls';
import { BaseModalController } from '../Modal/BaseModal';
import { Modal, ModalController, ModalDirective, modalProperty } from '../Modal/Modal';
import { TextBox } from '../TextBox/TextBox';
import template from './Support.html';

export class SupportModalController extends BaseModalController<Support> {
    public static readonly $inject = [
        '$uibModalInstance',
        '$scope',
        '$http',
        '$q',
        'control',
        'localization',
        'userSettings',
        'user',
        'logger',
        'tracking',
        'codeList',
        'dateTime',
        'license',
        'productInformation',
        'browser'
    ];

    public submitted: boolean;

    public subjectTextBox: TextBox;
    public message: string;
    public applicationVersion?: string;
    public operatingSystem?: string;
    public browserVersion?: string;

    private $http: ng.IHttpService;
    private $q: ng.IQService;
    private $scope: ng.IScope;
    private logger: LoggerService;
    private localization: LocalizationService;
    private userSettings: UserSettingsService;
    private user: UserService;
    private tracking: TrackingService;
    private codeLists: CodeListService;
    private dateTime: DateTimeService;
    private license: LicenseService;
    private currentDateTime: Date;
    private productInformationService: ProductInformationService;
    private browser: BrowserService;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        $http: ng.IHttpService,
        $q: ng.IQService,
        control: Support,
        localization: LocalizationService,
        userSettings: UserSettingsService,
        user: UserService,
        logger: LoggerService,
        tracking: TrackingService,
        codeLists: CodeListService,
        dateTime: DateTimeService,
        license: LicenseService,
        productInformationService: ProductInformationService,
        browser: BrowserService
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$http = $http;
            this.$q = $q;
            this.$scope = $scope;
            this.$scope['localization'] = this.localization = localization;
            this.userSettings = userSettings;
            this.user = user;
            this.logger = logger;
            this.tracking = tracking;
            this.codeLists = codeLists;
            this.dateTime = dateTime;
            this.license = license;
            this.productInformationService = productInformationService;
            this.browser = browser;

            // don't close the modal if save is pending (close on destroy)
            this.$scope.$on('modal.closing', (event: ng.IAngularEvent, result?: any) => {
                if (result !== 'destroy' && this.submitted) {
                    event.preventDefault();
                }
            });

            this.subjectTextBox = new TextBox({
                id: 'support-subject',
                title: new Translation('Agito.Hilti.Purchaser.Support.Subject'),
                disabled: new Bind('ctrl.submitted'),
                required: true
            });

            this.currentDateTime = new Date();

            this.applicationVersion = environment.applicationVersion;

            // uai
            const uai = this.browser.getBrowserData();
            this.operatingSystem = uai.os;
            this.browserVersion = uai.browser;
        })(this.$onInit);
    }

    public get region() {
        return this.codeLists.codelist[ProjectCodeList.Region].find(region => region.id == this.userSettings.settings.user.general.regionId.value) as Region;
    }

    public get supportOpeningHours() {
        return this.region.supportHours;
    }

    public get supportEmail() {
        return this.region.contactUrl;
    }

    public get supportPhone() {
        return this.region.supportPhone;
    }

    public get faqLinkMessage() {
        return this.askHiltiLink != null ?
            this.translate('Agito.Hilti.Profis3.Support.AskHilti.Message') :
            this.translate('Agito.Hilti.Profis3.Support.FAQ.Message');
    }

    public get faqLinkText() {
        return this.askHiltiLink != null ?
            this.translate('Agito.Hilti.Profis3.Region.Link.AskHilti') :
            this.translate('Agito.Hilti.Profis3.Region.Link.KnowledgeBaseUrl');
    }

    public get faqLinkUrl() {
        return this.askHiltiLink ?? this.kbUrl;
    }

    // In case of Ask Hilti link is present in database we use Ask Hilti link
    // otherwise we use Knowledge Base url (kbUrl)
    // if both are missing we should hide FAQ section in support popup
    public get askHiltiLink() {
        return this.productInformationService.regionLinksForUser(this.region.id)?.AskHiltiLink;
    }

    public get kbUrl() {
        return this.region.kbUrl;
    }

    public get hideFAQ() {
        return this.askHiltiLink == null &&
            (this.kbUrl == null || this.kbUrl.length == 0);
    }

    public get isSupportDataAvailable() {
        // Check if either the email or phone are defined.
        if (this.supportEmail || this.supportPhone) {
            return true;
        }
        else {
            return false;
        }
    }

    public get formatedDateTime() {
        return this.dateTime.format(this.currentDateTime, DateTimePattern.dateTime);
    }


    public get country() {
        return this.user.authentication.countryOfResidence
            ? this.user.authentication.countryOfResidence
            : this.user.authentication.country;
    }

    public get correlationId() {
        return this.control.applicationError?.correlationId;
    }

    public get userName() {
        return this.user.authentication.userName;
    }

    public get regionName() {
        return this.translate(this.region.nameResourceKey);
    }

    public get licenseType() {
        return this.license.getLicense();
    }

    public get translatedLicenseType() {
        return this.translate('Agito.Hilti.Profis3.License.' + this.licenseType);
    }

    private get form() {
        return this.$scope['form'] as ng.IFormController;
    }

    public translate(key: string) {
        return this.localization.getLocalizedString(key);
    }

    public close() {
        this.$uibModalInstance.close();
    }

    public save() {
        if (this.submitted || !this.form.$valid) {
            return;
        }

        this.submitted = true;

        const subject = this.subjectTextBox.model;
        const message = this.message;
        const regionId = this.userSettings.settings.user.general.regionId.value;

        const url = `${environment.supportServiceUrl}support/send`;

        const data: SupportRequest = {
            Subject: subject,
            Message: message,
            Design: null,
            DesignName: null,
            Logs: null,
            ApplicationVersion: this.applicationVersion,
            BrowserVersion: this.browserVersion,
            OperatingSystemVersion: this.operatingSystem,
            DateTime: this.currentDateTime.toUTCString(),
            RegionId: regionId,
            ApplicationType: SupportApplicationType.QuantityCalculator,
            Country: this.country,
            LicenseType: this.licenseType,
            CorrelationId: this.correlationId
        };

        // request
        this.$http.post<void>(url, data)
            .finally(() => {
                this.submitted = false;
            })
            .then(() => {
                this.close();
            })
            .catch(response => {
                this.logger.logServiceError(response, 'Support', 'SendEmailToSupport');

                return this.$q.reject(response);
            });

        this.tracking.trackOnSupportCalled();
    }

    public formatSupportOpeningHours(supportOpeningHours: string) {
        return this.translate('Agito.Hilti.Purchaser.Support.Info.OpeningHours').concat(': ').concat(supportOpeningHours);
    }

    public formatSupportEmail(supportEmail: string) {
        return this.translate('Agito.Hilti.Purchaser.Support.Info.Email').concat(': ').concat(supportEmail).replace('mailto:', '');
    }

    public formatSupportPhone(supportPhone: string) {
        return this.translate('Agito.Hilti.Purchaser.Support.Info.Phone').concat(': ').concat(supportPhone);
    }

    public formatSupportText(translationText: string, supportText: string) {
        return this.translate(translationText).concat(': ').concat(supportText);
    }

    public openUserManual() {
        // open url
        const url = formatKeyValue(environment.userManualUrl, { language: this.getSupportedUserManualLanguage() });
        window.open(url, '_blank');
    }

    public getSupportedUserManualLanguage() {

        const language = this.userSettings.getLanguage().culture.substring(0, 2).toUpperCase();

        switch (language) {
            case 'DE':
            case 'FR':
            case 'EN':
            case 'IT':
            case 'NL':
            case 'ES':
            case 'PT':
            case 'CZ':
            case 'HU':
            case 'PL':
            case 'RU':
            case 'SK':
                return language;
            case 'CS':
                return 'CZ';
            default:
                return 'EN';
        }
    }

    private toJsonString(data?: string | object): string {
        if (data != null && typeof (data) === 'object') {
            return JSON.stringify(data);
        }
        else if (data != null && typeof (data) === 'string') {
            return data;
        }

        return null;
    }
}

export interface ISupportConstructor {

}

@control('Support')
export class Support extends Modal<Support> {

    @modalProperty()
    public applicationError: IApplicationError;

    constructor(ctor?: ISupportConstructor) {
        super({
            template,
            templateUrl: 'Support.html',
            controller: SupportModalController,
            windowClass: 'default-modal support-modal',
            ...ctor
        });
    }

    public open(applicationError?: IApplicationError | ModalValue<IApplicationError>, onClose?: () => any) {
        if (applicationError != null) {
            if (applicationError instanceof ModalValue) {
                this.configProperty('applicationError', applicationError);
            }
            else {
                this.configPropertyAsValue('applicationError', applicationError);
            }
        }

        return super.open();
    }
}

class SupportController extends ModalController<Support> {
    public static readonly $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $uibModal: ng.ui.bootstrap.IModalService) {
        super(Support, $scope, $element, $attrs, $uibModal);
    }
}

export class SupportDirective extends ModalDirective {
    constructor() {
        super(SupportController, Support);
    }
}

