import {
    PurchaserDataEntity
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data';
import {
    DesignEntity
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.Design';
import { AnchorType } from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.Enums';
import {
    TrackBasicRequest, TrackDesignVerificationTimeRequest, TrackOnBomCreatedRequest, UserDetails
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Tracking';
import { GuidService } from '../../src/app/guid.service';
import { LoggerService } from '../../src/app/services/logger.service';
import { environment } from '../../src/environments/environment';
import { ProjectAndDesignController } from '../Controllers/project-and-design-controller';
import { BrowserService } from './browser-service';
import { IRequestConfig } from './http-interceptor-service';
import { UserService } from './user-service';
import { UserSettingsService } from './user-settings-service';

export class DateWithTimezone {
    public timestamp: string;
    public timezoneOffset: number;
}

/**
* proxy for tracking service that tracks design details
* tracking service is responsible for:
* - tracking details when new design is created or existing design is opened
* - tracking details when design is closed
*/
export interface ITrackingService {
    trackOnAppOpened(): ng.IPromise<void>;
    trackDesignVerificationTime(elapsedMilliseconds: number): ng.IPromise<void>;
    trackOnSupportCalled(): ng.IPromise<void>;
    trackOnCustomConnectionCreated(): ng.IPromise<void>;
    trackOnDesignFileImported(): ng.IPromise<void>;
    trackOnBomCreated(dataEntity: PurchaserDataEntity): ng.IPromise<void>;
    trackOnBomExportedToExcel(): ng.IPromise<void>;
    trackOnBomExportedToHol(): ng.IPromise<void>;
}

/**
 * proxy for tracking service that tracks design details
 * tracking service is responsible for:
 * - tracking details when new design is created or existing design is opened
 * - tracking details when design is closed
 */
export class TrackingService implements ITrackingService {
    public static readonly $inject = [
        '$http',
        'user',
        'logger',
        'guid',
        'userSettings',
        'browser'
    ];

    private readonly $http: ng.IHttpService;
    private readonly user: UserService;
    private readonly logger: LoggerService;
    private readonly guid: GuidService;
    private readonly userSettings: UserSettingsService;
    private readonly browser: BrowserService;

    /**
     * Constructor
     * @param $http
     * @param user
     * @param logger
     * @param guid
     * @param userSettings
     */
    constructor(
        $http: ng.IHttpService,
        user: UserService,
        logger: LoggerService,
        guid: GuidService,
        userSettings: UserSettingsService,
        browser: BrowserService
    ) {
        this.$http = $http;
        this.user = user;
        this.logger = logger;
        this.guid = guid;
        this.userSettings = userSettings;
        this.browser = browser;
    }

    public trackOnAppOpened(): ng.IPromise<void> {
        // set date
        const date = this.getJSONDateWithWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.trackingWebServiceUrl}TrackOnAppOpened`;
        const data: TrackBasicRequest = {
            UserDetails: userDetails,
            Version: environment.applicationVersion
        };

        const config: IRequestConfig = { ignoreErrors: true, responseType: 'json' };

        return this.$http.post<void>(url, data, config)
            .catch((response) => {
                this.logger.logServiceError(response, 'TrackingService', 'trackOnAppOpened');
            }) as ng.IPromise<void>;
    }

    public HideMail(checked: boolean) {
        const url = `${environment.trackingWebServiceUrl}HideMail/${checked}`;

        const config: IRequestConfig = {
            ignoreErrors: true,
            responseType: 'json'
        };
        return this.$http.post(url, null, config)
            .catch((response) => {
                this.logger.logServiceError(response, 'TrackingService', 'HideMail');
            });
    }

    public trackDesignVerificationTime(elapsedMilliseconds: number): ng.IPromise<void> {
        // set date
        const date = this.getJSONDateWithWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.trackingWebServiceUrl}TrackDesignVerificationTime`;
        const data: TrackDesignVerificationTimeRequest = {
            UserDetails: userDetails,
            ElapsedMilliseconds: elapsedMilliseconds,
            Version: environment.applicationVersion
        };

        const config: IRequestConfig = { ignoreErrors: true, responseType: 'json' };

        return this.$http.post<void>(url, data, config)
            .catch((response) => {
                this.logger.logServiceError(response, 'TrackingService', 'trackDesignVerificationTime');
            }) as ng.IPromise<void>;
    }

    public trackOnSupportCalled(): ng.IPromise<void> {
        // set date
        const date = this.getJSONDateWithWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.trackingWebServiceUrl}TrackOnSupportCalled`;
        const data: TrackBasicRequest = {
            UserDetails: userDetails,
            Version: environment.applicationVersion
        };

        const config: IRequestConfig = { ignoreErrors: true, responseType: 'json' };

        return this.$http.post<void>(url, data, config)
            .catch((response) => {
                this.logger.logServiceError(response, 'TrackingService', 'trackOnSupportCalled');
            }) as ng.IPromise<void>;
    }

    public trackOnCustomConnectionCreated(): ng.IPromise<void> {
        // set date
        const date = this.getJSONDateWithWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.trackingWebServiceUrl}TrackOnCustomConnectionCreated`;
        const data: TrackBasicRequest = {
            UserDetails: userDetails,
            Version: environment.applicationVersion
        };

        const config: IRequestConfig = { ignoreErrors: true, responseType: 'json' };

        return this.$http.post<void>(url, data, config)
            .catch((response) => {
                this.logger.logServiceError(response, 'TrackingService', 'trackOnCustomConnectionCreated');
            }) as ng.IPromise<void>;
    }

    public trackOnDesignFileImported(): ng.IPromise<void> {
        // set date
        const date = this.getJSONDateWithWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.trackingWebServiceUrl}TrackOnDesignFileImported`;
        const data: TrackBasicRequest = {
            UserDetails: userDetails,
            Version: environment.applicationVersion
        };

        const config: IRequestConfig = { ignoreErrors: true, responseType: 'json' };

        return this.$http.post<void>(url, data, config)
            .catch((response) => {
                this.logger.logServiceError(response, 'TrackingService', 'trackOnDesignFileImported');
            }) as ng.IPromise<void>;
    }

    public trackOnBomCreated(dataEntity: PurchaserDataEntity): ng.IPromise<void> {
        // set date
        const date = this.getJSONDateWithWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const included = dataEntity.Designs.filter(design => design.IsIncluded);
        const inserts = included.filter(design => design.AnchorType == null || design.AnchorType === AnchorType.Insert);
        const mortars = included.filter(design => design.AnchorType === AnchorType.Mortar || design.AnchorType === AnchorType.Capsule);

        const isCustomConnection = (design: DesignEntity) => {
            if (design.SelectedAnchor == null) {
                return false;
            }

            if (design.SelectedAnchor.IsManuallyAdded) {
                return true;
            } // manual connections are never default, always custom

            // connection is custom if selected anchor is not default one
            // default anchor is the one with minimal size
            const sizes = ProjectAndDesignController.findPurchaserDesignAnchorSizes(design.SelectedAnchor, null, this.guid);
            const size = design.SelectedAnchor.ArticleQuantity || 0;
            const minSize = Math.min(...sizes.map(anchor => anchor.articleQuantity)) || 0;
            return size > minSize;
        };

        const customInserts = inserts.filter(design => isCustomConnection(design));
        const customMortars = mortars.filter(design => isCustomConnection(design));

        const url = `${environment.trackingWebServiceUrl}TrackOnBomCreated`;
        const data: TrackOnBomCreatedRequest = {
            UserDetails: userDetails,
            Version: environment.applicationVersion,
            CustomInsert: `${customInserts.length} / ${inserts.length}`,
            CustomMortar: `${customMortars.length} / ${mortars.length}`
        };

        const config: IRequestConfig = { ignoreErrors: true, responseType: 'json' };

        return this.$http.post<void>(url, data, config)
            .catch((response) => {
                this.logger.logServiceError(response, 'TrackingService', 'trackOnBomCreated');
            }) as ng.IPromise<void>;
    }

    public trackOnBomExportedToExcel(): ng.IPromise<void> {
        // set date
        const date = this.getJSONDateWithWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.trackingWebServiceUrl}TrackOnBomExportedToExcel`;
        const data: TrackBasicRequest = {
            UserDetails: userDetails,
            Version: environment.applicationVersion
        };

        const config: IRequestConfig = { ignoreErrors: true, responseType: 'json' };

        return this.$http.post<void>(url, data, config)
            .catch((response) => {
                this.logger.logServiceError(response, 'TrackingService', 'trackOnBomExportedToExcel');
            }) as ng.IPromise<void>;
    }

    public trackOnBomExportedToHol(): ng.IPromise<void> {
        // set date
        const date = this.getJSONDateWithWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.trackingWebServiceUrl}TrackOnBomExportedToHol`;
        const data: TrackBasicRequest = {
            UserDetails: userDetails,
            Version: environment.applicationVersion
        };

        const config: IRequestConfig = { ignoreErrors: true, responseType: 'json' };

        return this.$http.post<void>(url, data, config)
            .catch((response) => {
                this.logger.logServiceError(response, 'TrackingService', 'trackOnBomExportedToHol');
            }) as ng.IPromise<void>;
    }

    private getUserDetails(timezoneOffset: number): UserDetails {
        // uai
        const uai = this.browser.getBrowserData();

        // contry of residence
        const countryOfResidenceFn = () => {
            if (this.user.authentication.countryOfResidence == null || this.user.authentication.countryOfResidence == '') {
                return this.user.authentication.subscription_info.AuthorizationEntryList[0].Country
            }
            return this.user.authentication.countryOfResidence;
        };

        const userDetails: UserDetails = {
            BrowserType: uai.browser,
            OperatingSystem: uai.os,
            TimezoneOffset: timezoneOffset,
            UserIP: '', // will be set on server side
            Username: '', // will be set on server side
            UUID: '00000000000000000000000000000000', // will be set on server side
            DiagnosticsAgreement: this.userSettings.settings.application.general.userPrivacy.value,
            CustomerId: this.user.authentication.customerId,
            CustomerOriginId: this.user.authentication.subscription_info.AuthorizationEntryList[0].CustomerOriginID,
            SalesOrg: '', // will be set on server side
            CustomerType: '', // will be set on server side
            CountryOfResidence: countryOfResidenceFn(),
            CustomerCountry: this.user.authentication.subscription_info.AuthorizationEntryList[0].Country,
            BuyingCustomerOriginId: this.user.authentication.subscription_info.AuthorizationEntryList[0].CustomerOriginID, // will be set on server side if license is available
            BuyingSalesOrg: '', // will be set on server side
            BuyingCustomerType: '', // will be set on server side
            License: this.user.authentication.license, // will be set on server side if license is available
            IsTrial: this.user.hasTrialLicense // will be set on server side if license is available
        };

        return userDetails;
    }

    private getJSONDateWithWithTimezoneOffset(): DateWithTimezone {
        const timestamp = new Date();

        const date = new DateWithTimezone();
        date.timestamp = timestamp.toISOString();
        // The timezone offset. Value should be correctly assigned ( negative if timezone is before UTC and positive if it is ahead )
        date.timezoneOffset = -timestamp.getTimezoneOffset();

        return date;
    }
}
